<template>
  <div>
    <div class="header">
    <h1>이레에비던스</h1>
    <h3>이레에비던스는 사용자 편리를 위해 아이디어를 찾아 앱을 제공합니다</h3>
    </div>
    <div class="content">
      <h4>제공 예정 앱 목록</h4>
      <div class="app-block">
        <div class="app-title">기기 충전시 열기</div>
        <div>
          <img :src="turn_on.profileUrl"  alt="turn on img" height="150" width="150" style="border-radius: 20%;"/>
        </div>
        <div class="app-discription">
          본 앱은 기기를 충전할 때<br/>
          홈 화면을 열어주는<br/>
          기능을 수행합니다<br/>
        </div>
        <div>
          <button @click="openPrivacyTurnOn()">개인정보 처리방침</button>
        </div>
      </div>
    </div>
    <footer>
      <span class="footer-head">COMPANY</span>
      <span class="footer-body">이레에비던스</span>
      <span class="footer-head">REPRESENTIVE</span>
      <span class="footer-body">윤기쁨</span>
      <span class="footer-head">E-MAIL</span>
      <span class="footer-body">erae.evidence@gmail.com</span>
      <span class="footer-head">TEL</span>
      <span class="footer-body">010-7901-1240</span>
      <p style="font-size: 14px; color: #555555">&copy; Erae Evidence. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  methods:{
    openPrivacyTurnOn() {
      this.$router.push('/privacy/turn-on')
    }
  },
  data() {
    return {
      turn_on: {
        profileUrl: require("../assets/logo_turn_on.png"),
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.header {
  width: 100%;
  background-color: #eeeeee;
  padding-top: 60px;
  padding-bottom: 30px;
}

.content {
  border-radius: 10px;
  margin: 30px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  padding: 20px 20px 70px 20px;
}

.app-block {
  width: 100%;
  margin-top: 40px;
  place-items: center;
}

.app-title {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  padding-bottom: 20px;
}

.app-discription {
  width: 200px;
  font-size: 17px;
  color: #555555;
  padding-top: 12px;
  padding-bottom: 20px;
}

.footer-head {
  font-size: 14px;
  color: #777777;
  margin-left: 4px;
  margin-right: 4px;
}

.footer-body {
  font-size: 14px;
  color: #555555;
  margin-left: 4px;
  margin-right: 4px;
}

button {
  border-radius: 10px;
  background-color: #e0e0e0;
  display: inline-block;
  padding: 8px 20px 8px 20px;
  color: #000000;
  font-weight: bold;
  text-align: center;
}

h1 {
  text-align: center;
}

h2 {
  text-align: left;
  font-size: 13px;
  color: #ffffff;
  padding-bottom: 15px;
}

h3 {
  text-align: center;
  margin: 3px 0 0;
}

h4 {
  text-align: left;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>
