import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Privacy from "@/components/Privacy.vue";
import PrivacyTurnOn from "@/components/PrivacyTurnOn.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/privacy/turn-on',
    name: 'PrivacyTurnOn',
    component: PrivacyTurnOn
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
