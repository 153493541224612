import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#0a2f58',
        // primary: '#28313f',
        secondary: '#51c2ee',
        anchor: '#628ef8',
        error: '#ec1816'
        // info:
      }
    }
  }
})
